import React from 'react'
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Arrow from '../utils/Arrow';

export default withOktaAuth(class NewUserEmailForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: '',
      email: '',
      temporaryPassword: '',
      userInfo: this.props.authState.idToken.claims,
      backdropOpen: false
    }
  }

  createFormDataObj = data => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return formData;
  }

  handleSubmit = (e) => {
    if ( this.state.firstName && this.state.email && this.state.temporaryPassword) {
      e.preventDefault();
      this.setState({backdropOpen: true})

      const data = {
        firstName: this.state.firstName,
        email: this.state.email,
        temporaryPassword: this.state.temporaryPassword,
      };

      axios.post(process.env.REACT_APP_API_DOMAIN + "/send-new-user-email", data, { headers: {
        'Content-Type': 'application/json',
        'X-Accept': 'application/json'
      }}).then(response => {
        console.log(response);
        window.location.href = '/admin?new_user_email_successful=true';
      }).catch(error => {
        console.log(error);
        window.location.href = '/admin?new_user_email_failed=' + error.response.data;
      })
    }
  }

  render() {
    return (
      <section className="relative">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <h1 className="font-headings text-5xl font-medium text-center">New User Email</h1>

        <form onSubmit={this.handleSubmit} name="contact" className="max-w-xl mx-auto px-8 py-8 bg-gray-50 border border-gray-200 rounded mt-8">
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <input 
                id="firstName" 
                type="text" 
                className="form-input w-full text-gray-800 border border-gray-200 rounded" 
                placeholder="First name" 
                onChange={e => this.setState({firstName: e.target.value})}
                required={true} />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <input 
                id="email" 
                type="email" 
                className="form-input w-full text-gray-800 border border-gray-200 rounded" 
                placeholder="Email address" 
                onChange={e => this.setState({email: e.target.value})}
                required={true} />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <input 
                id="temporaryPassword" 
                type="text" 
                className="form-input w-full text-gray-800 border border-gray-200 rounded" 
                placeholder="Temporary password" 
                onChange={e => this.setState({temporaryPassword: e.target.value})}
                required={true} />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <button onClick={this.handleSubmit} className="btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                Submit
                <Arrow />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }
})
