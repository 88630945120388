import React from 'react';
import axios from 'axios';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from '@mui/material/IconButton';

export default function LikeButton(props) {

  const [isLiked, setIsLiked] = React.useState(props.isLiked);

  const updateIsLiked = (newIsLiked) => {
    setIsLiked(newIsLiked);

    axios.post(process.env.REACT_APP_API_DOMAIN + "/update-contribution-like", {
      uniqueId: props.uniqueId,
      userId: props.userId,
      isLiked: newIsLiked

    }).then(response => {
      console.log(response);

    }).catch(error => {
      console.log(error);
    })
  };

  return (isLiked ? (
      <IconButton color="error" aria-label="unfavorite">
        <FavoriteIcon onClick={() => {updateIsLiked(false)}} />
      </IconButton>
    ) : (
      <IconButton aria-label="favorite">
        <FavoriteIcon onClick={() => {updateIsLiked(true)}} />
      </IconButton>
    )
  );
}
