import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Toast(props) {
  return (
  	<Snackbar
      open={props.open}
      message=""
      onClose={props.close}
      autoHideDuration={props.success ? 3000 : 6000}
    >
      <Alert severity={props.success ? "success" : "error"} className="w-full">
        {props.text}
        &nbsp;
      </Alert>
    </Snackbar>
  );
}

export default Toast;