import React from 'react';

import Template from '../Template';
import AdminContributionsTable from '../../partials/AdminContributionsTable';

export default function AllContributions() {
  return <Template body={<AdminContributionsTable />}  pageHierarchy={[
    { name: 'Admin Console', href: '/admin' },
    { name: 'All Contributions', href: '/admin/all-contributions' },
  ]} />
};

