import React from 'react';

import Template from '../Template';
import NewUserEmailForm from '../../partials/NewUserEmailForm';

export default function NewUserEmail() {
  return <Template body={<NewUserEmailForm />}  pageHierarchy={[
    { name: 'Admin Console', href: '/admin' },
    { name: 'New User Email', href: '/admin/new-user-email' },
  ]} />
};

