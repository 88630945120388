import React from 'react'

import Template from './Template';

export default function Payouts() {
  return (
    <Template body={"Payouts"} pageHierarchy={[
      { name: 'My Payouts', href: '/payouts' },
    ]} />
  )
}
