import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import NoRowsOverlay from './NoRowsOverlay';
import axios from 'axios';
import Toast from './Toast';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { XCircleIcon, CheckCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid'

function TernaryToggle(props) {

  const [toggleState, setToggleState] = React.useState(props.status);

  const handleToggle = (
    event: React.MouseEvent<HTMLElement>,
    newToggleState: string | null,
  ) => {
    if (newToggleState !== null) {
      setToggleState(newToggleState);
      setDatabaseToggleState(props.uniqueId, newToggleState);
    }
  };

  const setDatabaseToggleState = (uniqueId, newToggleState) => {
    axios.post(process.env.REACT_APP_API_DOMAIN + "/update-contribution-status", {
      uniqueId: uniqueId,
      status: newToggleState

    }).then(response => {
      console.log(response);

    }).catch(error => {
      console.log(error);
    })
  };

  return (
    <ToggleButtonGroup
      value={toggleState}
      exclusive
      onChange={handleToggle}
      aria-label="text alignment"
    >
      <ToggleButton value="Pending" aria-label="left aligned">
        <QuestionMarkCircleIcon className="w-6 h-6 rounded-full flex-shrink-0 opacity-40" />
      </ToggleButton>
      <ToggleButton value="Rejected" aria-label="centered">
        <XCircleIcon className="w-6 h-6 rounded-full flex-shrink-0 opacity-40" />
      </ToggleButton>
      <ToggleButton value="Approved" aria-label="right aligned">
        <CheckCircleIcon className="w-6 h-6 rounded-full flex-shrink-0 opacity-40" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const columns = [
  {
    field: 'status',
    headerName: 'Status',
    width: 170,
    renderCell: (params) => <TernaryToggle uniqueId={params.getValue(params.id, "uniqueId")} status={params.getValue(params.id, "status")} />
  },
  {
    field: 'resolvedTitle',
    headerName: 'Title',
    width: 350,
    renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_">{params.getValue(params.id, "resolvedTitle")}</a>
  },
  {
    field: 'excerpt',
    headerName: 'Excerpt',
    width: 800,
    renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_">{params.getValue(params.id, "excerpt")}</a>
  }
];

export default withOktaAuth(class ContributionsHome extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      userInfo: this.props.authState.idToken.claims,
      loading: true,
      showFailureToast: false,
      failureToastText: null,
      contributions: null
    }
  }

  componentDidMount() {
    this.getContributions();
  }

  getContributions() {
    var url = process.env.REACT_APP_API_DOMAIN + "/get-contributions?userId=" + this.state.userInfo.sub;
    axios.get(url).then(response => {
      this.setState({
        contributions: response.data,
        loading: false
      });

    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    });
  }

  updateContributionStatus(uniqueId, status) {
    axios.post(process.env.REACT_APP_API_DOMAIN + "/update-contribution-status", {
      uniqueId: uniqueId,
      status: status

    }).then(response => {
      console.log(response);

    }).catch(error => {
      console.log(error);
    })
  }

  approvedContribution(uniqueId) {
    this.updateContributionStatus(uniqueId, "Approved");
  }

  rejectContribution(uniqueId) {
    this.updateContributionStatus(uniqueId, "Pending");
  }

  render() {
    var bodyContent = this.state.loading ? (
      <div className="mx-auto -mx-8 -mt-8" style={{width: "calc(100% + 4rem)"}}>
        <LinearProgress color="inherit" />
      </div>
    ) : (
      <div className="w-full h-full">
        <DataGridPro
          rows={this.state.contributions}
          columns={columns}
          pageSize={100}
          disableSelectionOnClick
          rowsPerPageOptions={[]}
          rowHeight={70}
          components={{
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: NoRowsOverlay,
          }}
          getRowId={(row) => row.itemId} />
      </div>
    )

    return (
      <section className="relative h-full">
        <Toast success={false} text={this.state.failureToastText} close={(event, reason) => this.setState({showFailureToast: false})} open={this.state.showFailureToast} />
        {bodyContent}
      </section>
    )
  }
})