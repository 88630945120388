import React from 'react';

import Template from './Template';
import AllContributionsHome from '../partials/AllContributionsHome';

export default function AllContributions() {

  return (
    <Template 
      body={<AllContributionsHome />} 
      pageHierarchy={[
        { name: 'All Contributions', href: '/all-contributions' }
      ]} 
      />
  )
}
