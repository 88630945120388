import React from 'react'
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardActionArea } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import InputTags from './InputTags';
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import LikeButton from './LikeButton';

export default function MediaCard(props) {

  const [modalOpen, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  const handleDeleteClick = () => {
    closeModal();
    var deleteContributionURL = process.env.REACT_APP_API_DOMAIN + "/delete-contribution";
    axios.post(deleteContributionURL, {
      uniqueId: props.contribution.uniqueId,
    }).then(response => {
      props.onDelete();
    }).catch(error => {
      console.log(error);
      props.onDelete();
    });
  };

  var imageUrl = (props.contribution.topImageUrl === undefined || props.contribution.topImageUrl === null) ? require("../assets/svg/rainbow.svg") : props.contribution.topImageUrl;
  var excerpt = (props.contribution.excerpt === undefined || props.contribution.excerpt === null) ? "" : (props.contribution.excerpt.length > 150 ? props.contribution.excerpt.slice(0, 150) + " ..." : props.contribution.excerpt);

  console.log(props.contribution.fathomTags)

  return (
    <Card sx={{ maxWidth: 345}} key={props.contribution.uniqueId} >
      <CardActionArea>
        <a href={props.contribution.resolvedUrl} target="blank_">
          <CardMedia
            component="img"
            height="140"
            image={imageUrl}
            alt={props.contribution.resolvedTitle} />
          <CardContent>
            <Typography gutterBottom variant="h7" component="div">
              {props.contribution.resolvedTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {excerpt}
            </Typography>
          </CardContent>
        </a>
      </CardActionArea>

      {props.mine && <CardActions disableSpacing>
        <IconButton aria-label="delete">
          <DeleteIcon onClick={openModal} />
        </IconButton>
        <InputTags uniqueId={props.contribution.uniqueId} tags={props.contribution.fathomTags} />
      </CardActions>}

      {!props.mine && <CardActions disableSpacing>
        <LikeButton isLiked={props.contribution.isLiked} uniqueId={props.contribution.uniqueId} userId={props.contribution.currentUserId} />
      </CardActions>}

      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setModalOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => closeModal()}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Delete contribution
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this contribution? Once deleted, it cannot be retrieved without contacting support.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-gray-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => handleDeleteClick()}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => closeModal()}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Card>
  );
}