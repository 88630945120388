import React from 'react';

import Template from './Template';
import ProfileForm from '../partials/ProfileForm';

function Profile() {
  return <Template body={<ProfileForm />}  pageHierarchy={[
    { name: 'My Profile', href: '/profile' },
  ]} />
};

export default Profile;
