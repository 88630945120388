import React from 'react';
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import LinearProgress from '@mui/material/LinearProgress';

import Toast from './Toast';
import ContributionsTable from './ContributionsTable';

export default withOktaAuth(class ContributionsHome extends React.Component {

  constructor(props) {
    super(props)

    var search = window.location.search;
    var params = new URLSearchParams(search);
    var requestToken = params.get('request_token');

    this.state = {
      userInfo: this.props.authState.idToken.claims,
      pocketUsername: null,
      pocketAccessToken: null,
      loading: true,
      newUser: true,
      redirect: (requestToken !== undefined && requestToken !== null && requestToken !== ""),
      requestToken: requestToken,
      showSuccessToast: false,
      successToastText: null,
      showFailureToast: false,
      failureToastText: null,
    }
  }

  componentDidMount() {
    if (this.state.redirect) {
      this.handleRedirect();
    } else {
      this.handleNonRedirect();
    }
  }

  handleNonRedirect() {
    var profileUrl = process.env.REACT_APP_API_DOMAIN + "/get-user?userId=" + this.state.userInfo.sub;
    axios.get(profileUrl).then(response => {
      if (response.data.pocketAccessToken === null || response.data.pocketAccessToken === undefined || response.data.pocketAccessToken === "") {
        this.setState({
          loading: false,
          newUser: true
        });

      } else {
        this.setState({
          newUser: false,
          pocketUsername: response.data.pocketUsername,
          pocketAccessToken: response.data.pocketAccessToken,
        }, () => {
          this.getContributions()
          this.retrieveContributions()
        })
      }

    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }
    
  handleRedirect() {
    var profileUrl = process.env.REACT_APP_API_DOMAIN + "/get-user?userId=" + this.state.userInfo.sub;
    axios.get(profileUrl).then(response => {
      if (response.data.pocketAccessToken === null || response.data.pocketAccessToken === undefined || response.data.pocketAccessToken === "") {
        var accessTokenUrl = process.env.REACT_APP_API_DOMAIN + "/pocket-access-token?requestToken=" + this.state.requestToken + "&userId=" + this.state.userInfo.sub;
        axios.get(accessTokenUrl, { headers: {
          'Content-Type': 'application/json',
          'X-Accept': 'application/json'

        }}).then(response => {
          this.setState({
            redirect: false,
            showSuccessToast: true,
            successToastText: "Successfully connected to your Pocket account!"
          }, () => {
            this.handleNonRedirect();
          })
          
        }).catch(error => {
          console.log(error);
          this.setState({
            loading: false,
            showFailureToast: true,
            failureToastText: "Pocket connection failed. Please connect to your Pocket account again."
          })
        })

      } else {
        // Redirect to the version of the page without the redirect-related url query param
        // if the user already has an access token (this would happen if they refresh the
        // page).
        window.location.href = window.location.pathname;
      }

    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }

  getContributions() {
    var url = process.env.REACT_APP_API_DOMAIN + "/get-contributions?userId=" + this.state.userInfo.sub;
    axios.get(url).then(response => {
      this.setState({
        contributions: response.data,
        loading: false
      });

    }).catch(error => {
      console.log(error);
      this.setState({
        contributions: [],
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    });
  }

  retrieveContributions() {
    // Add an additional pocket-retrieve-contributions call for whenever the page loads.
    // Will remove once we optimize resync experience and get the periodic call working.
    var retrieveContributionsUrl = process.env.REACT_APP_API_DOMAIN + "/pocket-retrieve-contributions";
    axios.post(retrieveContributionsUrl, {
      userId: this.state.userInfo.sub

    }).then(response => {
      this.getContributions()

    }).catch(error => {
      console.log(error);
      this.setState({
        showFailureToast: true,
        failureToastText: error.message
      })
    });
  }

  handleConnectClick() {
    axios.get(process.env.REACT_APP_API_DOMAIN + "/pocket-request-token").then(response => {
      var code = response.data;
      var pocketUrl = 'https://getpocket.com/auth/authorize?request_token=' + code + '&redirect_uri=' + window.location.origin + '/contributions?request_token=' + code;
      window.location.href = pocketUrl;

    }).catch(error => {
      console.log(error);
      this.setState({
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }

  render() {
    const refreshContributions = () => {
      this.setState({loading: true}, () => {
        this.handleNonRedirect();
      })
    }

    var bodyContent = this.state.loading ? (
      <div className="mx-auto -mx-8 -mt-8" style={{width: "calc(100% + 4rem)"}}>
        <LinearProgress color="inherit" />
      </div>
    ) : (this.state.newUser ? (
          <section name="contact" className="mx-auto px-8 py-8 bg-gray-50 border border-gray-200 rounded mt-8 max-w-xl">
            <h3 className="font-standard text-xl text-left font-medium">Connect to the Pocket chrome extension to flag articles and resources.</h3>
            <button onClick={this.handleConnectClick} className="mt-8 px-4 py-2 border border-gray-300 rounded bg-white hover:bg-gray-100">
              Connect to Pocket
            </button>
          </section>
      ) : (
        <ContributionsTable 
          contributions={this.state.contributions} 
          onDelete={refreshContributions} 
          resync={refreshContributions}
          />
      )
    )

    return (
      <section className="relative h-full">
        <Toast success={false} text={this.state.failureToastText} close={(event, reason) => this.setState({showFailureToast: false})} open={this.state.showFailureToast} />
        <Toast success={true} text={this.state.successToastText} close={(event, reason) => this.setState({showSuccessToast: false})} open={this.state.showSuccessToast} />
        {bodyContent}
      </section>
    )
  }
})
