import React from 'react';

import Template from './Template';
import ContributionsHome from '../partials/ContributionsHome';

export default function Pocket() {
  return (
    <Template 
      body={<ContributionsHome />} 
      pageHierarchy={[
        { name: 'My Contributions', href: '/contributions' }
      ]} 
      />
  )
}
