import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import Masonry from '@mui/lab/Masonry';
import ContentLink from '../utils/ContentLink';
import MediaCard from '../utils/MediaCard';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import NoRowsOverlay from './NoRowsOverlay';
import { Switch } from '@headlessui/react'
import Avatar from '@mui/material/Avatar';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ContributionsMasonryTable(props) {
  return (
    <div className="-mr-4"> 
      <Masonry columns={{sm: 2, md:4, xl: 5}} spacing={2}>
        {props.contributions.map((contribution) => (
          <MediaCard mine={true} key={contribution.resolvedUrl} contribution={contribution} onDelete={props.onDelete} />
        ))}
      </Masonry>
    </div>
  )
}

function ContributionsGridTable(props) {

  const columns = [
    {
      field: 'topImageUrl',
      headerName: '',
      width: 60,
      renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_"><Avatar src={(params.getValue(params.id, "topImageUrl") === undefined || params.getValue(params.id, "topImageUrl") === null) ? require("../assets/svg/rainbow.svg") : params.getValue(params.id, "topImageUrl")} /></a>
    },
    {
      field: 'resolvedTitle',
      headerName: 'Title',
      width: 350,
      renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_">{params.getValue(params.id, "resolvedTitle")}</a>
    },
    {
      field: 'excerpt',
      headerName: 'Excerpt',
      width: 1000,
      renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_">{params.getValue(params.id, "excerpt")}</a>
    }
  ];

  return (
    <div style={{width: "calc(100%)", height: "calc(100% - 7rem)"}}>
      <DataGridPro
        rows={props.contributions}
        columns={columns}
        pageSize={25}
        disableSelectionOnClick
        rowsPerPageOptions={[]}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: NoRowsOverlay,
        }}
        getRowId={(row) => row.itemId} />
    </div>
  )
}

export default withOktaAuth(class ContributionsTable extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      userInfo: this.props.authState.idToken.claims,
      contributions: props.contributions,
      toggleEnabled: false,
      masonryTable: <ContributionsMasonryTable contributions={props.contributions} onDelete={this.props.onDelete} />,
      gridTable: <ContributionsGridTable contributions={props.contributions} />
    }
  }

  render() {
    return (!this.state.contributions || this.state.contributions.length === 0) ? (
      <div>
        <section name="contact" className="mx-auto px-12 py-12 bg-gray-50 border border-gray-200 rounded mt-8 max-w-3xl">
          <h3 className="mb-12 font-bold font-headings text-3xl text-left font-medium">Your Pocket account is connected!</h3>
          <p className="mb-2">But you don't have any contributions yet. Start adding items to your Pocket via the <ContentLink href="https://chrome.google.com/webstore/detail/save-to-pocket/niloccemoadcdkdjlinkgdfekeahmflj?hl=en" external={true} anchor="Pocket brower extension" /> and they will appear here.</p>
          <p className="mb-12">Find more detailed instructions at the <ContentLink href="https://www.fathomnetwork.io/guide" external={true} anchor="Fathom Product Guide" />.</p>

          <div className="mt-8 mx-auto grid grid-cols-2 grid-flow-col-dense gap-24 pb-12">
            <img src={require("../assets/img/save-article.png")} className="mx-auto mb-4 w-100 h-100 border border-gray-300" alt="Cash"/>
            <div>
              <div className="mt-8">
                <img src={require("../assets/svg/pocket.svg")} className="mb-4 w-12 h-12" alt="Pocket"/>
                <h3 className="font-bold  text-2xl font-headings">Save articles</h3>
                <p className="mt-4 text-gray-800">
                  While you're reading, save the most helpful content you come across to Pocket.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto grid grid-cols-2 grid-flow-col-dense gap-24">
            <div>
              <img src={require("../assets/svg/cash.svg")} className="w-20 h-20" alt="Earn"/>
              <h3 className="font-bold  text-2xl font-headings">Earn attribution</h3>
              <p className="mt-4 text-gray-800">
                That's it. Fathom will sync your Pocket contributions and assign you attribution credits based on the value of your contributions.
              </p>
            </div>
            <img src={require("../assets/img/contributions.png")} className="mx-auto mb-4 w-100 h-100 border border-gray-300" alt="Cash" />
          </div>
        </section>
        <div className="h-8"></div>
      </div>

    ) : (
      <div className="h-full">
        <table className="border-b border-gray-200 align-middle w-full mb-8">
          <tbody>
            <tr className="pb-8">
              <td>
                <Switch.Group as="div" className="flex items-center mb-8">
                  <Switch
                    checked={this.state.toggleEnabled}
                    onChange={() => {this.setState({toggleEnabled: !this.state.toggleEnabled})}}
                    className={classNames(
                      this.state.toggleEnabled ? 'bg-gray-400' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2'
                    )} >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        this.state.toggleEnabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )} />
                  </Switch>
                  <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-400">{this.state.toggleEnabled ? "Cards view" : "Table view"}</span>
                  </Switch.Label>
                </Switch.Group>
              </td>
              <td>
                <div className="ml-auto mr-0 w-fit mb-8">
                  <button
                    onClick={this.props.resync}
                    className="transition-colors ease-in-out delay-350 ml-3 w-fit h-fit flex inline-flex px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                    >
                    Re-sync with Pocket
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        { this.state.toggleEnabled ? this.state.gridTable : this.state.masonryTable }
      </div>
    );
  }
})