import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter, Redirect, Switch } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import { LicenseInfo } from '@mui/x-license-pro';

import './css/style.scss';

import Contributions from './pages/Contributions';
import Referrals from './pages/referrals/Referrals';
import NewReferral from './pages/referrals/NewReferral';
import Payouts from './pages/Payouts';
import Profile from './pages/Profile';
import PageNotFound from './pages/PageNotFound';
import AdminConsole from './pages/admin/AdminConsole';
import NewUserEmail from './pages/admin/NewUserEmail';
import UsersTracker from './pages/admin/UsersTracker';
import AdminContributions from './pages/admin/AdminContributions';
import AllContributions from './pages/AllContributions';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback'
});

class App extends Component {

  constructor(props) {
    super(props);
    this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
      props.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };
  }

  render() {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);
    return (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/all-contributions" />
          </Route>
          <SecureRoute exact path="/contributions" component={Contributions}/>
          <SecureRoute exact path="/all-contributions" component={AllContributions}/>
          <SecureRoute exact path="/referrals" component={Referrals}/>
          <SecureRoute exact path="/referrals/new" component={NewReferral}/>
          <SecureRoute exact path="/payouts" component={Payouts}/>
          <SecureRoute exact path="/profile" component={Profile}/>
          <SecureRoute exact path="/admin" component={AdminConsole}/>
          <SecureRoute exact path="/admin/new-user-email" component={NewUserEmail}/>
          <SecureRoute exact path="/admin/contributions" component={AdminContributions}/>
          <SecureRoute exact path="/admin/users" component={UsersTracker}/>
          <Route exact path="/login/callback" component={LoginCallback}/>
          <SecureRoute component={PageNotFound} />
        </Switch>
      </Security>
    );
  }
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (<Router><AppWithRouterAccess/></Router>);
  }
}

export default RouterApp;
