import React from 'react';
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import LinearProgress from '@mui/material/LinearProgress';
import Masonry from '@mui/lab/Masonry';
import ContentLink from '../utils/ContentLink';
import MediaCard from '../utils/MediaCard';
import LikeButton from '../utils/LikeButton';
import { DataGridPro } from '@mui/x-data-grid-pro';
import NoRowsOverlay from './NoRowsOverlay';
import { Switch } from '@headlessui/react'
import Avatar from '@mui/material/Avatar';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function ContributionsMasonryTable(props) {
  return (
    <div className="-mr-4"> 
      <Masonry columns={{sm: 2, md:4, xl: 5}} spacing={2}>
        {props.contributions.map((contribution) => (
          <MediaCard userId={props.userId} mine={false} key={contribution.resolvedUrl} contribution={contribution} onDelete={props.onDelete} />
        ))}
      </Masonry>
    </div>
  )
}

function ContributionsGridTable(props) {

  const columns = [
    {
      field: 'isLiked',
      headerName: '',
      width: 60,
      renderCell: (params) => <LikeButton isLiked={params.getValue(params.id, "isLiked")} uniqueId={params.getValue(params.id, "uniqueId")} userId={params.getValue(params.id, "currentUserId")} />
    },
    {
      field: 'topImageUrl',
      headerName: '',
      width: 60,
      renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_"><Avatar src={(params.getValue(params.id, "topImageUrl") === undefined || params.getValue(params.id, "topImageUrl") === null) ? require("../assets/svg/rainbow.svg") : params.getValue(params.id, "topImageUrl")} /></a>
    },
    {
      field: 'resolvedTitle',
      headerName: 'Title',
      width: 350,
      renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_">{params.getValue(params.id, "resolvedTitle")}</a>
    },
    {
      field: 'excerpt',
      headerName: 'Excerpt',
      width: 800,
      renderCell: (params) => <a href={params.getValue(params.id, "resolvedUrl")} target="blank_">{params.getValue(params.id, "excerpt")}</a>
    }
  ];

  return (
    <div style={{width: "calc(100%)", height: "calc(100% - 6rem)"}}>
      <DataGridPro
        rows={props.contributions}
        columns={columns}
        pageSize={25}
        disableSelectionOnClick
        rowsPerPageOptions={[]}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: NoRowsOverlay,
        }}
        getRowId={(row) => row.itemId} />
    </div>
  )
}

export default withOktaAuth(class ContributionsHome extends React.Component {

  constructor(props) {
    super(props)

    var search = window.location.search;
    var params = new URLSearchParams(search);
    var requestToken = params.get('request_token');

    this.state = {
      userInfo: this.props.authState.idToken.claims,
      pocketUsername: null,
      pocketAccessToken: null,
      loading: true,
      newUser: true,
      redirect: (requestToken !== undefined && requestToken !== null && requestToken !== ""),
      requestToken: requestToken,
      showSuccessToast: false,
      successToastText: null,
      showFailureToast: false,
      failureToastText: null,
      toggleEnabled: false
    }
  }

  componentDidMount() {
    if (this.state.redirect) {
      this.handleRedirect();
    } else {
      this.handleNonRedirect();
    }
  }

  handleNonRedirect() {
    var profileUrl = process.env.REACT_APP_API_DOMAIN + "/get-user?userId=" + this.state.userInfo.sub;
    axios.get(profileUrl).then(response => {
      if (response.data.pocketAccessToken === null || response.data.pocketAccessToken === undefined || response.data.pocketAccessToken === "") {
        this.setState({
          loading: false,
          newUser: true
        });

      } else {
        this.setState({
          newUser: false,
          pocketUsername: response.data.pocketUsername,
          pocketAccessToken: response.data.pocketAccessToken,
        }, () => {
          this.getContributions()
          this.retrieveContributions()
        })
      }

    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }
    
  handleRedirect() {
    var profileUrl = process.env.REACT_APP_API_DOMAIN + "/get-user?userId=" + this.state.userInfo.sub;
    axios.get(profileUrl).then(response => {
      if (response.data.pocketAccessToken === null || response.data.pocketAccessToken === undefined || response.data.pocketAccessToken === "") {
        var accessTokenUrl = process.env.REACT_APP_API_DOMAIN + "/pocket-access-token?requestToken=" + this.state.requestToken + "&userId=" + this.state.userInfo.sub;
        axios.get(accessTokenUrl, { headers: {
          'Content-Type': 'application/json',
          'X-Accept': 'application/json'

        }}).then(response => {
          this.setState({
            redirect: false,
            showSuccessToast: true,
            successToastText: "Successfully connected to your Pocket account!"
          }, () => {
            this.handleNonRedirect();
          })
          
        }).catch(error => {
          console.log(error);
          this.setState({
            loading: false,
            showFailureToast: true,
            failureToastText: "Pocket connection failed. Please connect to your Pocket account again."
          })
        })

      } else {
        // Redirect to the version of the page without the redirect-related url query param
        // if the user already has an access token (this would happen if they refresh the
        // page).
        window.location.href = window.location.pathname;
      }

    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }

  getContributions() {
    var url = process.env.REACT_APP_API_DOMAIN + "/get-contributions?userId=" + this.state.userInfo.sub + "&returnAll=true";
    axios.get(url).then(response => {
      this.setState({
        contributions: response.data,
        loading: false
      });

    }).catch(error => {
      console.log(error);
      this.setState({
        contributions: [],
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    });
  }

  retrieveContributions() {
    // Add an additional pocket-retrieve-contributions call for whenever the page loads.
    // Will remove once we optimize resync experience and get the periodic call working.
    var retrieveContributionsUrl = process.env.REACT_APP_API_DOMAIN + "/pocket-retrieve-contributions";
    axios.post(retrieveContributionsUrl, {
      userId: this.state.userInfo.sub

    }).then(response => {
      this.getContributions()

    }).catch(error => {
      console.log(error);
      this.setState({
        showFailureToast: true,
        failureToastText: error.message
      })
    });
  }

  handleConnectClick() {
    axios.get(process.env.REACT_APP_API_DOMAIN + "/pocket-request-token").then(response => {
      var code = response.data;
      var pocketUrl = 'https://getpocket.com/auth/authorize?request_token=' + code + '&redirect_uri=' + window.location.origin + '/contributions?request_token=' + code;
      window.location.href = pocketUrl;

    }).catch(error => {
      console.log(error);
      this.setState({
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }

  render() {
    var bodyContent = this.state.loading ? (
      <div className="mx-auto -mx-8 -mt-8" style={{width: "calc(100% + 4rem)"}}>
        <LinearProgress color="inherit" />
      </div>
    ) : (
      (!this.state.contributions || this.state.contributions.length === 0) ? (
      <div>
        <section name="contact" className="mx-auto px-12 py-12 bg-gray-50 border border-gray-200 rounded mt-8 max-w-3xl">
          <h3 className="mb-12 font-bold font-headings text-3xl text-left font-medium">Your Pocket account is connected!</h3>
          <p className="mb-2">But you don't have any contributions yet. Start adding items to your Pocket via the <ContentLink href="https://chrome.google.com/webstore/detail/save-to-pocket/niloccemoadcdkdjlinkgdfekeahmflj?hl=en" external={true} anchor="Pocket brower extension" /> and they will appear here.</p>
          <p className="mb-12">Find more detailed instructions at the <ContentLink href="https://www.fathomnetwork.io/guide" external={true} anchor="Fathom Product Guide" />.</p>

          <div className="mt-8 mx-auto grid grid-cols-2 grid-flow-col-dense gap-24 pb-12">
            <img src={require("../assets/img/save-article.png")} className="mx-auto mb-4 w-100 h-100 border border-gray-300" alt="Cash"/>
            <div>
              <div className="mt-8">
                <img src={require("../assets/svg/pocket.svg")} className="mb-4 w-12 h-12" alt="Pocket"/>
                <h3 className="font-bold  text-2xl font-headings">Save articles</h3>
                <p className="mt-4 text-gray-800">
                  While you're reading, save the most helpful content you come across to Pocket.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto grid grid-cols-2 grid-flow-col-dense gap-24">
            <div>
              <img src={require("../assets/svg/cash.svg")} className="w-20 h-20" alt="Earn"/>
              <h3 className="font-bold  text-2xl font-headings">Earn attribution</h3>
              <p className="mt-4 text-gray-800">
                That's it. Fathom will sync your Pocket contributions and assign you attribution credits based on the value of your contributions.
              </p>
            </div>
            <img src={require("../assets/img/contributions.png")} className="mx-auto mb-4 w-100 h-100 border border-gray-300" alt="Cash" />
          </div>
        </section>
        <div className="h-8"></div>
      </div>

    ) : (
      <div className="h-full">
        <table className="border-b border-gray-200 align-middle w-full mb-8">
          <tbody>
            <tr className="pb-8">
              <td>
                <Switch.Group as="div" className="flex items-center mb-8">
                  <Switch
                    checked={this.state.toggleEnabled}
                    onChange={() => {
                      this.setState({ 
                        loading: true,
                        toggleEnabled: !this.state.toggleEnabled
                      }, () => { this.getContributions()})}
                    }
                    className={classNames(
                      this.state.toggleEnabled ? 'bg-gray-400' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-200 focus:ring-offset-2'
                    )} >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        this.state.toggleEnabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )} />
                  </Switch>
                  <Switch.Label as="span" className="ml-3">
                    <span className="text-sm font-medium text-gray-400">{this.state.toggleEnabled ? "Cards view" : "Table view"}</span>
                  </Switch.Label>
                </Switch.Group>
              </td>
            </tr>
          </tbody>
        </table>

        { this.state.toggleEnabled ? <ContributionsGridTable contributions={this.state.contributions} /> : <ContributionsMasonryTable contributions={this.state.contributions} onDelete={this.props.onDelete} userId={this.props.authState.idToken.claims.sub} /> }
      </div>)
    )

    return (
      <section className="relative h-full">
        {bodyContent}
      </section>
    )
  }
})
