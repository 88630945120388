import React from 'react';

import Template from '../Template';
import Arrow from '../../utils/Arrow';
import Toast from '../../partials/Toast';

function AdminNavigation(argument) {
  return <div>
    <a href="/admin/new-user-email" className="text-right mt-4 btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
      New user email
      <Arrow />
    </a>
    <br />
    <a href="/admin/contributions" className="text-right mt-4 btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
      All contributions
      <Arrow />
    </a>
    <br />
    <a href="/admin/users" className="text-right mt-4 btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
      Users
      <Arrow />
    </a>    
  </div>
}

export default class AdminConsole extends React.Component {

  constructor(props) {
    super(props)

    var search = window.location.search;
    var params = new URLSearchParams(search); 

    this.state = {
      successSnackBar: params.get('new_user_email_successful'),
      failureSnackBar: params.get('new_user_email_failed')
    }
  }

  render() {
    return (
      <>
        <Toast success={true} text={"New user email successful."} close={(event, reason) => this.setState({successSnackBar: false})} open={this.state.successSnackBar} />
        <Toast success={false} text={this.state.failureSnackBar} close={(event, reason) => this.setState({failureSnackBar: false})} open={this.state.failureSnackBar} />
        <Template 
          body={<AdminNavigation />}
          pageHierarchy={[
            { name: 'Admin Console', href: '/admin' },
          ]} />
      </>
    )
  }
}
