import React from 'react'
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro } from '@mui/x-data-grid-pro';
import NoRowsOverlay from './NoRowsOverlay';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 150,
    date: "date",
    valueFormatter: ({ value }) => (new Date(value)).toDateString(),
  },
  {
    field: 'referreeName',
    headerName: 'Name',
    width: 160,
  },
  {
    field: 'referreeEmail',
    headerName: 'Email',
    width: 180,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 120,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    flex: 1
  }
];

export default withOktaAuth(class ReferralsTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userInfo: this.props.authState.idToken.claims,
      rows: [],
      loading: true
    }
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_API_DOMAIN + "/get-referrals?userId=" + this.state.userInfo.sub).then(response => {
      console.log(response.data)
      this.setState({rows: response.data}, () => {this.setState({loading: false})});
    }).catch(error => {
      console.log(error);
    })
  }  

  setUserInfo() {
    if (!this.props.authState || !this.props.authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      this.setState({userInfo: null});
    } else {
      this.setState({userInfo: this.props.authState.idToken.claims});
    }
  }

  render() {
    return (
      <DataGridPro
        rows={this.state.rows}
        columns={columns}
        pageSize={25}
        disableSelectionOnClick
        rowsPerPageOptions={[]}
        loading={this.state.loading}
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: NoRowsOverlay,
        }}
      />
    );
  }
})
