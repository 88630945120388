import React from 'react'

import Template from '../Template';
import NewReferralForm from '../../partials/NewReferralForm';

export default function NewReferral() {
  return (
    <Template body={<NewReferralForm />} pageHierarchy={[
      { name: 'My Referrals', href: '/referrals' },
      { name: 'New Referral', href: '/referrals/new' },
    ]} />
  )
}


