import React, { Fragment, Component } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { UserAddIcon, DocumentAddIcon, BookOpenIcon, CogIcon } from '@heroicons/react/outline'
import { SelectorIcon, UserCircleIcon } from '@heroicons/react/solid'
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { withOktaAuth } from '@okta/okta-react';
import Logo from '../partials/Logo';
import Waves from '../utils/Waves';
import background from "../assets/svg/sidenav-background.svg";

function isAdminUser(userInfo) {
  return ["ashkonf@gmail.com"].includes(userInfo?.email);
}

const navigation = [
  { name: 'All Contributions', href: '/all-contributions', icon: BookOpenIcon, admin: false },
  { name: 'My Contributions', href: '/contributions', icon: DocumentAddIcon, admin: false },
  { name: 'My Referrals', href: '/referrals', icon: UserAddIcon, admin: false },
  { name: 'Admin Console', href: '/admin', icon: CogIcon, admin: true },
]

const references = [
  { name: 'Product Guide', href: process.env.REACT_APP_WEBSITE_DOMAIN + '/guide' },
  { name: 'Terms of Service', href: process.env.REACT_APP_WEBSITE_DOMAIN + '/tos' },
  { name: 'Privacy Policy', href: process.env.REACT_APP_WEBSITE_DOMAIN + '/privacy' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function inAppSection(item) {
  return window.location.pathname.startsWith(item.href);
}

function BreadCrumbs(props) {
  return (
    <div role="presentation">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          href="/"
          key="/"
        >
          <HomeIcon />
          <span className="sr-only">Home</span>
        </Link>
        {props.pageHierarchy.map((page) => 
          (page.current ? 
            (
              <Typography
                sx={{ display: 'flex', alignItems: 'center' }}
                color="text.primary"
                key={page.href}
              >
                {page.name}
              </Typography>
            ) : (
              <Link
                underline="hover"
                sx={{ display: 'flex', alignItems: 'center' }}
                color="inherit"
                href={page.href}
                key={page.href}
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
              </Link>
            )
          )
        )}
      </Breadcrumbs>
    </div>
  )
}

function UserMenu(props) {
  return (
    <Menu as="div" className="px-3 relative inline-block text-left border-b border-gray-300 pb-6">
      <div>
        <Menu.Button className="group w-full rounded-md px-1 py-2 text-sm text-left font-medium text-gray-700 transition-colors ease-in-out delay-250 hover:bg-gray-200">
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <UserCircleIcon className="w-10 h-10 rounded-full flex-shrink-0 opacity-40" />
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-gray-900 text-sm font-medium truncate">{props.userInfo?.name}</span>
                <span className="text-gray-500 text-sm truncate">{props.userInfo?.email}</span>
              </span>
            </span>
            <SelectorIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-20 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md border border-gray-200 shadow-lg bg-white ring-1 ring-gray-300 ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="/profile"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  View profile
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={props.logout}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm w-full text-left'
                  )}>
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

function Navigation(props) {
  return (
    <nav className="px-3 mt-6 border-b border-gray-300 pb-6 z-10">
      <div className="space-y-1">
        {navigation.map((item) => (
          (!item.admin || isAdminUser(props.userInfo)) && <a
            key={item.name}
            href={item.href}
            className={classNames(
              inAppSection(item) ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-200',
              'group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors ease-in-out delay-250'
            )}
            aria-current={inAppSection(item.href) ? 'page' : undefined}
          >
            <item.icon
              className={classNames(
                inAppSection(item) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                'mr-3 flex-shrink-0 h-6 w-6'
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}
      </div>
    </nav>
  )
}

function Footer() {
  return (
    <nav className="px-3 mt-6 fixed bottom-4 z-10">
      <div className="space-y-1">
        {references.map((item) => (
          <a
            key={item.name}
            href={item.href}
            target="blank_"
            className='text-gray-500 hover:text-gray-800 group flex items-center px-2 text-sm font-medium rounded-md transition-colors ease-in-out delay-250'
          >
            {item.name}
          </a>
        ))}
      </div>
    </nav>
  )
}

function SideBar(props) {
  return (
    <div className="flex flex-col w-64 fixed h-full border-r border-gray-300 bg-auto bg-cover bg-center" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex items-center px-3 w-full border-b border-gray-300 h-20">
        <div className="transition-colors ease-in-out delay-250 hover:bg-gray-200 px-2 py-2 rounded-md w-full">
          <Logo />
        </div>
      </div>

      <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto overflow-x-hidden">
        <UserMenu userInfo={props.userInfo} logout={props.logout} />
        <Navigation userInfo={props.userInfo} />
        <Footer />
        <Waves width="300" />
      </div>
    </div>
  )
}

export default withOktaAuth(class Template extends Component {

  setUserInfo() {
    if (!this.props.authState || !this.props.authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      this.setState({userInfo: null});
    }
  }

  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {userInfo: this.props.authState?.idToken.claims};
  }

  async login() {
    await this.props.oktaAuth.signInWithRedirect();
  }

  async logout() {
    await this.props.oktaAuth.signOut();
  }

  render() {
    return (
      <div className="h-screen">
        <SideBar userInfo={this.state.userInfo} logout={this.logout} />

        <main className="pl-64 h-screen">
          <table className="border-b border-gray-200 px-8 bg-white z-50 h-20 align-middle w-full fixed" style={{width: "calc(100vw - 16rem)"}}>
            <tbody>
              <tr>
                <td className="pl-8">
                  <BreadCrumbs pageHierarchy={this.props.pageHierarchy} />
                </td>
                <td>
                  <div className="ml-auto mr-0 w-fit pr-8">
                    {this.props.headerActions}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="w-full px-8 pb-8 pt-28" style={{height: "calc(100vh)"}}>
            {this.props.body}
          </div>
        </main>
      </div>
    )
  }
})
