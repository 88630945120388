import React from 'react'

import Template from '../Template';
import ReferralsTable from '../../partials/ReferralsTable';
import Toast from '../../partials/Toast';

export default class Referrals extends React.Component {

  constructor(props) {
    super(props)

    var search = window.location.search;
    var params = new URLSearchParams(search); 

    this.state = {
      successSnackBar: params.get('referral_received'),
      failureSnackBar: params.get('referral_failed')
    }
  }

  render() {
    var headerActions = (
      <a
        href="/referrals/new"
        className="transition-colors ease-in-out delay-350 ml-3 w-fit h-fit flex inline-flex px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
        <span role="img" aria-label="plus">➕</span> &nbsp; New Referral
      </a>
    )

    return (
      <>
        <Toast success={true} text={"Referral successful"} close={(event, reason) => this.setState({successSnackBar: false})} open={this.state.successSnackBar} />
        <Toast success={false} text={this.state.failureSnackBar} close={(event, reason) => this.setState({failureSnackBar: false})} open={this.state.failureSnackBar} />
        <Template 
          body={<ReferralsTable />}
          pageHierarchy={[
            { name: 'My Referrals', href: '/referrals' },
          ]} 
          headerActions={headerActions} />
      </>
    )
  }
}
