import React from 'react'
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import { UserIcon, MailIcon, ChevronDownIcon, CalendarIcon } from '@heroicons/react/outline'
import Toast from './Toast';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

export default withOktaAuth(class NewReferralForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      userInfo: this.props.authState.idToken.claims,
      firstName: this.props.authState.idToken.claims.firstName,
      lastName: this.props.authState.idToken.claims.lastName,
      pocketUsername: this.props.authState.idToken.claims.pocketUsername,
      pocketConnectedDate: this.props.authState.idToken.claims.pocketConnectedDate,
      showSuccessToast: false,
      successToastText: null,
      showFailureToast: false,
      failureToastText: null,
      loading: true
    }

    console.log(this.state.userInfo);
  }

  componentDidMount() {
    var url = process.env.REACT_APP_API_DOMAIN + "/get-user?userId=" + this.state.userInfo.sub;
    axios.get(url).then(response => {
      console.log(response);
      this.setState({
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        pocketUsername: response.data.pocketUsername,
        pocketConnectedDate: response.data.pocketConnectedDate,
        loading: false
      })
    }).catch(error => {
      console.log(error);
      this.setState({
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }

  setUserInfo() {
    if (!this.props.authState || !this.props.authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      this.setState({userInfo: null});
    } else {
      // Update ID token
      this.props.oktaAuth.tokenManager.renew("idToken").then(() =>
        this.setState({userInfo: this.props.authState.idToken.claims}, () => {
          console.log(this.state.userInfo);
        })
      )
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    
    this.setUserInfo()

    this.setState({loading: true})

    axios.post(process.env.REACT_APP_API_DOMAIN + "/update-user", {
      userId: this.state.userInfo.sub,
      profileUpdates: {
        firstName: this.state.firstName,
        lastName: this.state.lastName
      }

    }).then(response => {
      console.log(response);
      this.setState({
        loading: false,
        showSuccessToast: true,
        successToastText: "Profile updated."
      })

    }).catch(error => {
      console.log(error);
      this.setState({
        loading: false,
        showFailureToast: true,
        failureToastText: error.message
      })
    })
  }

  FormField(props) {
    return (
      <div className="isolate -space-y-px mb-4">
        <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
        <div>
          <div className="mt-1 relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              {props.icon === "profile" && <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              {props.icon === "email" && <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              {props.icon === "pocket" && <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
              {props.icon === "calendar" && <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
            </div>
            <input
              type={props.type}
              name={props.name}
              id={props.name}
              className={"focus:ring-gray-300 focus:border-gray-300 block w-full pl-10 form-input border border-gray-200 rounded " + (props.readOnly ? "text-gray-500 placeholder-gray-300" : "text-gray-800  placeholder-gray-500")}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              onChange={props.onChange}
              readOnly={props.readOnly}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.state.loading && <div className="fixed x-auto -mx-8 -mt-8" style={{width: "calc(100% + 4rem)"}}>
          <LinearProgress color="inherit" />
        </div>}

        <section className="relative max-w-xl mx-auto">

          <Toast success={false} text={this.state.failureToastText} close={(event, reason) => this.setState({showFailureToast: false})} open={this.state.showFailureToast} />
          <Toast success={true} text={this.state.successToastText} close={(event, reason) => this.setState({showSuccessToast: false})} open={this.state.showSuccessToast} />

          <h1 className="font-headings text-6xl font-medium text-center">My Profile</h1>

          <form name="contact" className="mx-auto px-8 py-8 bg-gray-50 border border-gray-200 rounded mt-8">
            <div className="grid grid-cols-2 gap-x-3">
              <this.FormField name={"firstName"} type={"text"} readOnly={false} defaultValue={this.state.firstName} placeholder="Your first name" label={"First Name"} onChange={e => this.setState({firstName: e.target.value})} icon="profile" />
              <this.FormField name={"lastName"} type={"text"} readOnly={false} defaultValue={this.state.lastName} placeholder="Your last name" label={"Last Name"} onChange={e => this.setState({lastName: e.target.value})} icon="profile" />
            </div>
            <this.FormField name={"email"} type={"email"} readOnly={true} defaultValue={this.state.userInfo?.email} placeholder="Your email address" label="Email Address" icon="email" />
            <this.FormField name={"pocketUsername"} type={"text"} readOnly={true} defaultValue={this.state.pocketUsername} placeholder="Your Pocket username" label="Pocket Username" icon="pocket" />
            <this.FormField name={"pocketConnectedDate"} type={"text"} readOnly={true} defaultValue={this.state.pocketConnectedDate} placeholder="The date of your first Pocket connection" label="Pocket Connected Date" icon="calendar" />

            <button onClick={this.handleSubmit} className="text-right mt-4 btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
              {this.state.loading ? <CircularProgress color="inherit" size="1.4rem" sx={{marginRight: 1, marginLeft: 1}} /> : "Save"}
            </button>        
          </form>
        </section>
      </div>
    );
  }
})
