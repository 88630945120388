import React from 'react'

import Arrow from '../utils/Arrow';
import Template from './Template';

function PageNotFoundContent() {
  return (
    <section className="relative max-w-xl mx-auto pt-20 text-center">
      <h3 className="h3 font-headings mb-6 text-center">This is not the <br /> page you are looking for.</h3>
      <p className="mx-auto max-w-md text-xl font-standard mb-8 text-center">There isn't a page at this location. Please double check the URL's spelling or head back home.</p>

      <a href="/" className="mx-auto btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
        Back home
        <Arrow />
      </a>   
    </section>
  )
}

export default function PageNotFound() {
  return (
    <Template body={<PageNotFoundContent />} pageHierarchy={[]} />
  )
}
