import React from 'react';
import axios from 'axios';
import { Cancel } from "@mui/icons-material";
import { Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";

const Tags = ({ data, handleDelete }) => {
  return (
    <Box
      sx={{
        background: "#283240",
        height: "100%",
        display: "flex",
        padding: "0.4rem",
        margin: "0 0.5rem 0 0",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </Box>
  );
};

export default function InputTags(props) {

  const [tags, SetTags] = useState(props.tags ? props.tags : []);
  const tagRef = useRef();

  const updateTags = (tags) => {
    axios.post(process.env.REACT_APP_API_DOMAIN + "/update-contribution-tags", {
      uniqueId: props.uniqueId,
      fathomTags: tags

    }).then(response => {
      console.log(response);

    }).catch(error => {
      console.log(error);
    })
  }

  const handleDelete = (value) => {
    const newtags = tags.filter((val) => val !== value);
    SetTags(newtags);
    updateTags(newtags)
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const newtags = [...tags, tagRef.current.value];
    SetTags(newtags);
    tagRef.current.value = "";
    updateTags(newtags);
  };

  return (
    <Box sx={{ flexGrow: 1, paddingRight: "12px" }}>
      <form onSubmit={handleOnSubmit}>
        <TextField
          inputRef={tagRef}
          fullWidth
          variant='standard'
          size='small'
          sx={{ margin: "1rem 0" }}
          margin='none'
          placeholder={(!tags || tags.length < 5) ? "Enter tags" : ""}
          InputProps={{
            startAdornment: (
              <Box sx={{ margin: "0 0.2rem 0 0", display: "flex" }}>
                {tags.map((data, index) => {
                  return (
                    <Tags data={data} handleDelete={handleDelete} key={index} />
                  );
                })}
              </Box>
            ),
          }}
        />
      </form>
    </Box>
  );
}
