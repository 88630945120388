import React from 'react'
import axios from 'axios';
import { withOktaAuth } from '@okta/okta-react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Arrow from '../utils/Arrow';

export default withOktaAuth(class NewReferralForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      userInfo: this.props.authState.idToken.claims,
      backdropOpen: false
    }
  }

  setUserInfo() {
    if (!this.props.authState || !this.props.authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      this.setState({userInfo: null});
    }
  }

  componentDidUpdate(prevProps) {
    this.setUserInfo();
    console.log(this.state.userInfo);
  }

  createFormDataObj = data => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return formData;
  }

  handleSubmit = (e) => {
    if ( this.state.name && this.state.email) {
      e.preventDefault();
      this.setState({backdropOpen: true})

      const { name, email, message } = this.state;
      const data = {
        referrer_name: this.state.userInfo?.name,
        referrer_email: this.state.userInfo?.email,
        referree_name: name,
        referree_email: email,
        message: message,
        userId: this.state.userInfo.sub
      };

      axios.post(process.env.REACT_APP_API_DOMAIN + "/new-referral", data, { headers: {
        'Content-Type': 'application/json',
        'X-Accept': 'application/json'
      }}).then(response => {
        console.log(response);
        window.location.href = '/referrals?referral_received=true';
      }).catch(error => {
        console.log(error);
        window.location.href = '/referrals?referral_failed=' + error.response.data;
      })
    }
  }

  render() {
    const {name, email, message} = this.state

    return (
      <section className="relative">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.backdropOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <h1 className="font-headings text-6xl font-medium text-center">New Referral</h1>

        <form onSubmit={this.handleSubmit} name="contact" className="max-w-xl mx-auto px-8 py-8 bg-gray-50 border border-gray-200 rounded mt-8">
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <input 
                id="name" 
                type="text" 
                className="form-input w-full text-gray-800 border border-gray-200 rounded" 
                placeholder="Your friend's name" 
                value={name}
                onChange={e => this.setState({name: e.target.value})}
                required={true} />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full px-3">
              <input 
                id="email" 
                type="email" 
                className="form-input w-full text-gray-800 border border-gray-200 rounded" 
                placeholder="Your friend's email address" 
                value={email}
                onChange={e => this.setState({email: e.target.value})}
                required={true} />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-3">
            <div className="w-full px-3">
              <textarea 
                id="message" 
                type="text" 
                className="form-input w-full text-gray-800 border border-gray-200 rounded" 
                placeholder="Anything else we should know?" 
                value={message}
                onChange={e => this.setState({message: e.target.value})}
                rows="4"/>
            </div>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3">
              <button onClick={this.handleSubmit} className="btn transition-colors ease-in-out delay-350 bg-black hover:bg-gray-800 text-white font-standard border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-4 focus:ring-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400">
                Submit
                <Arrow />
              </button>
            </div>
          </div>
        </form>
      </section>
    );
  }
})
